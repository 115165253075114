import React from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import { useDocTitle } from '../components/CustomHook';
import emailjs from 'emailjs-com';
import Notiflix from 'notiflix';
import ScrollToTopOnEnter from '../components/ScrollToTopOnEnter';


const Contact = () => {
    useDocTitle('GOLDEN SURF AND TOURS | Surf School, Rentals and Tours - Send us a message')
    function sendEmail(e) {
        e.preventDefault();

        // Access form input values
        const { first_name, last_name, email, message } = e.target.elements;

        // Check if any field is empty
        if (!first_name.value || !last_name.value || !email.value || !message.value) {
            // Display an error message or perform any other action
            Notiflix.Report.failure(
                'Error',
                'Please fill in all the required fields.',
                'Okay',
            );
            return; // Exit the function early
        }

        emailjs.sendForm('service_oe3z8xt', 'template_06r1k7y', e.target, 'xVrqp-_C9NjypvJh5')
            .then((result) => {
                console.log(result.text);
                Notiflix.Report.success(
                    'Success',
                    '"Thanks for sending a message, we\'ll be in touch soon."',
                    'Okay',
                );
            }, (error) => {
                console.log(error.text);
                Notiflix.Report.failure(
                    'An error occured',
                    'Please try sending the message again.',
                    'Okay',
                );
            });
    }
    return (
        <>
            <div>
                <NavBar />
            </div>
            <div className="flex justify-center items-center mt-8 w-full bg-black py-12 lg:py-24 bg-gray-200">
                <ScrollToTopOnEnter />
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">

                    <form onSubmit={sendEmail}>

                        <div className="w-full bg-gray-900 p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                            <div className="flex">
                                <h1 className="font-bold text-center lg:text-left text-yellow-300 uppercase text-4xl">Send us a message</h1>
                            </div>
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                <input name="first_name" className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    type="text" placeholder="First Name*" />
                                <input name="last_name" className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    type="text" placeholder="Last Name*" />
                                <input name="email" className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    type="email" placeholder="Email*" />
                                <input name="phone_number" className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    type="number" placeholder="Phone" />
                            </div>
                            <div className="my-4">
                                <textarea name="message" placeholder="Message*" className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"></textarea>
                            </div>
                            <div className="my-2 w-1/2 lg:w-2/4">
                                <button type="submit" className="uppercase text-sm font-bold tracking-wide bg-yellow-500 hover:bg-yellow-900 text-black p-3 rounded-lg w-full 
                                focus:outline-none focus:shadow-outline">
                                    Send Message
                                </button>
                            </div>
                        </div>
                    </form >
                    <div
                        className="w-full  lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-yellow-200 rounded-2xl font-bold">
                        <div className="flex flex-col">

                            <div className="flex my-1 w-2/3 lg:w-3/4">
                                <div className="flex flex-col">
                                    <i className="fas fa-map-marker-alt pt-2 pr-2" />
                                </div>
                                <div className="flex flex-col">
                                    <h2 className="text-2xl">How to Find Us</h2>
                                    <p className="text-gray-800">Kuta, Badung Regency, Bali, Indonesia</p>
                                    <p className="text-gray-800">Find us on the Kuta Beach next to the Skatepark to the south side.</p>
                                    <p className="mt-1">
                                        <a  className="underline" underline href="https://goo.gl/maps/6wsxiHQNC1iW2D638" target="_blank" rel="noopener noreferrer">                                        
                                            GOOGLE MAPS
                                        </a>
                                    </p>
                                    <h2 className="text-2xl mt-1">Opening Hours</h2>
                                    <p className="text-gray-800">6am-7pm, Every day all year long.</p>

                                </div>
                            </div>

                            <div className="flex my-1 w-2/3 lg:w-1/2">
                                <div className="flex flex-col">
                                    <i className="fas fa-phone-alt pt-2 pr-2" />
                                </div>

                                <div className="flex flex-col">
                                    <h2 className="text-2xl">Call Us</h2>
                                    <p className="text-gray-800">Tel: +6287850073305</p>

                                    <div className='mt-1'>
                                        <h2 className="text-2xl">Send an E-mail</h2>
                                        <p className="text-gray-800">goldensurfandtour@mail.com</p>
                                    </div>

                                </div>
                            </div>

                            <div className="flex my-4">
                                <a href="https://api.whatsapp.com/send?phone=6281262292992&app=facebook&entry_point=page_cta&fbclid=IwAR1-bK26MMNwcLJgGVL5tJpBeX-biiwgUGsUNMHoS-LSkIgA5Xe5s7tHRe0" target="_blank" rel="noreferrer" className="rounded-full flex justify-center bg-black h-12 text-yellow-300  w-12 inline-block mx-1 text-center pt-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 16 16" className='fill-current font-black hover:animate-pulse'>
                                        <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                                    </svg>
                                </a>
                                <a href="https://www.facebook.com/Goldenholiday.SurfLessonandTour" target="_blank" rel="noreferrer" className="rounded-full flex justify-center bg-black h-12 text-yellow-300  w-12 inline-block mx-1 text-center pt-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 16 16" className='fill-current font-black hover:animate-pulse'>
                                        <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                                    </svg>
                                </a>
                                <a href="https://instagram.com/golden_surfandtour?igshid=NTc4MTIwNjQ2YQ==" target="_blank" rel="noreferrer" className="rounded-full flex justify-center bg-black h-12 text-yellow-300  w-12 inline-block mx-1 text-center pt-2">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 16 16" className='fill-current font-black hover:animate-pulse'>
                                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                                    </svg>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>


    )
}

export default Contact;