import React from 'react';
import Footer from '../components/Footer';
import About from '../components/About';
import Services from '../components/Services';
import NavBar from '../components/Navbar/NavBar';
import {Helmet} from "react-helmet";


const Home = () => {
    return (
        <>
            <Helmet>
                <title>Golden Surf and Tour</title>
                <meta name='description' content='We offer surf lessons, surf rentals on Kuta Beach and tours in Bali.' />
                <meta name="keywords" content="surf lessons, surf rentals, surfboard rentals, surf rental, surfboard rental, Bali, Kuta Beach, Indonesia,
                 Nusa Penida Tour, Uluwatu Tour, Gili Islands tour, Ubud tour, Tanah Lot Tour, tours"/>
            </Helmet>

            <NavBar />
            <Services />
            <About />
            <Footer />
        </>

    )
}

export default Home;

