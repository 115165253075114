import React from 'react';
import img from '../images/school_3.jpg';
import { Link } from 'react-router-dom';

const About = () => {
    return (
        <>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6" id='about' >

                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="fade-up">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <img alt="people learning to surf img" className="rounded-t float-right" src={img} />
                        </div>
                        <div className="flex-col my-4 text-center lg:text-left lg:my-0 lg:justify-end w-full lg:w-1/2 px-8" data-aos="zoom-in" data-aos-delay="500">
                            
                            <h3 className="text-3xl  text-gray-100 font-bold">We are small laid back school in Bali, Kuta.</h3>
                            <div>
                                <p className='my-3 text-xl text-yellow-300 font-semibold'>We offer not only surf lessons but also surf rentals and tours. Whether you prefer spontaneous beach encounters or contacting us through WhatsApp, we are here to cater to your needs. Feel free to inquire about the best time to visit and surf.</p>
                            </div>
                            
                            <div>
                                <p className='my-3 text-xl text-yellow-300 font-semibold'>Kuta offers consistent waves throughout the year, making it an ideal place for surfers of all skill levels. Whether you're a beginner or an experienced surfer, there are waves suitable for everyone.</p>
                            </div>
                            <div>
                                <p className='my-3 text-xl text-yellow-300 font-semibold'>Kuta is easily accessible, especially for travelers visiting Bali. It is located just a short drive from Ngurah Rai International Airport, making it a convenient choice for surfers arriving in Bali.</p>
                            </div>
                            
                            <Link to="/contact" className="text-black font-bold bg-yellow-500 hover:bg-yellow-800 inline-flex items-center justify-center w-full px-6 py-2 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0 group">
                                Get Started
                                <svg className="w-4 h-4 ml-1 group-hover: translate-x-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                            </Link>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default About;