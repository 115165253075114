import React from 'react';
import img from '../images/school_1.jpg'; 
import img2 from '../images/surf_storage_2_resized.jpg';
import img3 from '../images/nusa_2.jpg';
import { useNavigate } from 'react-router-dom';

const Services = () => {    
    const navigate = useNavigate();

    const navigateToContact = () => {        
        navigate('/contact');
    };

    return (
        <div id='services'>
            <section className="bg-gray-100 py-12" data-aos="zoom-in-down">
                    <div className="my-2 py-2  md:my-4  md:py-4">                       
                        
                        <div className='flex justify-center'>
                            
                        </div>                        
                    </div>

                    <div className="px-6 md:px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5" onClick={navigateToContact}>                            
                            
                                <div id='lessons' className="bg-black transition-all ease-in-out duration-400  overflow-hidden text-yellow-300 hover:bg-gray-900 hover:text-white rounded-lg shadow-2xl p-3 group">
                                    <div className="m-2 text-justify text-md">
                                    <img alt="surf school img" className="rounded-t group-hover:scale-[1.04] transition duration-1000 ease-in-out service-image" src={img}  />
                                        <h2 className="font-semibold my-4 text-2xl text-center">Surf Lessons</h2>
                                        <p className="text-md font-medium">
                                            Our experienced instructors offer safe and enjoyable surf lessons for all skill levels. Learn the fundamentals, improve your technique, and catch the waves with confidence. We provide all the necessary equipment for a hassle-free experience. Located near fantastic surf spots, you'll have access to incredible waves. Join us and immerse yourself in the vibrant surf culture of Bali Kuta. Book your surf lessons today and experience the thrill of riding the waves! 
                                        </p>
                                        <br />
                                        <p className="text-lg font-large text-center">
                                            How much? 200 000 IDR/60 minutes.
                                        </p>
                                    </div>
                                </div>                            
                            
                                <div id='rentals' className="bg-black transition-all ease-in-out duration-400  overflow-hidden text-yellow-300 hover:bg-gray-900 hover:text-white rounded-lg shadow-2xl p-3 group">
                                    <div className="m-2 text-justify text-md">
                                    <img alt="surfboard dock img" className="rounded-t group-hover:scale-[1.04] transition duration-1000 ease-in-out service-image" src={img2} />
                                        <h2 className="font-semibold my-4 text-2xl text-center">Surf Rentals</h2>
                                        <p className="text-md font-medium">
                                            Welcome to our surf rental service in Bali Kuta! Explore the waves at your own pace with our high-quality surfboards available for rent. Our knowledgeable staff can help you choose the perfect board for your skill level. Trust in the well-maintained equipment for a safe and enjoyable experience. Conveniently located near excellent surf spots, our shop is the ideal starting point for your surfing adventures. Embrace the thrill of riding the waves and make the most of your time in Bali Kuta with our surf rental service.                                         
                                        </p>
                                        <br />
                                        <p className="text-lg font-large text-center">
                                        How much? 50 000 IDR/60 minutes.
                                        </p>
                                    </div>
                                </div>                            
                            
                                <div id='tours' className="bg-black transition-all ease-in-out duration-400  overflow-hidden text-yellow-300 hover:bg-gray-900 hover:text-white rounded-lg shadow-2xl p-3 group  sm:col-span-2 lg:col-span-1">
                                    <div className="m-2 text-justify text-md">
                                        <img alt="nusa penida island img" className="rounded-t group-hover:scale-[1.04] transition duration-1000 ease-in-out service-image" src={img3}/>
                                        <h2 className="font-semibold my-4 text-2xl text-center ">Tours</h2>
                                        <p className="text-md font-medium">
                                            Experience the enchanting island of Bali with our personalized tours. Our experienced guides will create tailored itineraries based on your interests. Explore stunning beaches on Nusa Penida and the Gili Islands, known for their pristine waters and vibrant marine life. Immerse yourself in Ubud's rich culture and visit iconic temples like Tanah Lot and Uluwatu. Let us handle transportation for a safe and convenient experience. Contact us to plan your dream tour in Bali today!
                                        </p>
                                        <br />
                                        <p className="text-lg font-large text-center">
                                        How much? Contact us.
                                        </p>
                                    </div>
                                </div>                            
                        </div>
                    </div>
            </section>

            
        </div>
    )
}

export default Services;